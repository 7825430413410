@import "styles/mixins/_mixins.less";

:root {
  --header-background: @color-bg-main-dark;
  --header-right-menu-color: @color-white;
  --pinned-collection-background: @color-neutral-light;
  --pinned-collection-color: @color-black;
}

@keyframes hide-header {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-@header-height);
  }
}

@keyframes show-header {
  from {
    transform: translateY(-@header-height);
  }
}

.header {
  width: 100%;
  border-bottom: thin solid @color-neutral;

  background: var(--header-background);
  transition: background-color 200ms ease-in-out;

  .headerContent {
    width: 100%;
    height: @header-height;
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 var(--unified-padding-right-large) 0 var(--unified-padding-left-large);
  }

  &--hidden {
    animation: hide-header 0.9s var(--slide-in-easing);
    transform: translateY(-@header-height);
  }

  &--displayed {
    animation: show-header 0.4s var(--slide-in-easing);
  }

  &__left {
    display: flex;
    flex-direction: row;
  }

  &__logo {
    display: flex;
    align-items: center;
    width: 10.7rem;
    height: 3.8rem;
    position: relative;
  }

  &__cache-proxy {
    display: flex;
    align-items: center;
    margin-left: @gap-regular;
  }

  &__links {
    right: @gap-regular * 2;
    display: flex;
    align-items: center;
    flex-direction: row;
    margin-left: auto;

    .popover-container {
      display: inline-block;
    }
  }

  &__link {
    margin-left: @gap-regular * 2;
    text-decoration: none;
    display: inline-block;

    &--icon {
      font-size: 2.8rem;
      position: relative;
      display: flex;
      align-items: center;
      height: 100%;
      color: @color-font-main-light;
    }

    &--disabled {
      cursor: default;
      color: @color-neutral;
    }

    &--collections {
      cursor: pointer;
    }

    &-title {
      font-size: 1.3rem;
      position: relative;
      top: -0.5rem;
      margin-right: 0.6rem;
      max-width: 10.0rem;
    }

    .label {
      position: absolute;
      bottom: 0;
      right: -0.5rem;
    }

    .media-breakpoint-down (@screen-md, {
      margin-left: @gap-regular;

      &--desktop {
        display: none !important;
      }
    });

    &:first-child {
      margin-left: 0 !important;
    }
  }

  &__chapters {
    display: flex;
    flex-grow: 1;
    justify-content: flex-start;
    margin-left: @gap-regular * 2;
    height: 100%;
  }

  &__hm {
    display: none;
    margin-right: @gap-regular;
    color: var(--header-right-menu-color);

    .media-breakpoint-down (@screen-md, {
      display: flex;
    });
  }

  &__mobile {
    width: 100%;
    position: absolute;
    top: @header-height;
    left: 0;
    background: @color-bg-secondary-dark;
    z-index: 10;
    opacity: 0;

    overflow: hidden;
    max-height: 0;
    transition: max-height 200ms ease-in-out;

    @media @smartphone {
      padding-left: @gap-regular;
    }

    &--opened {
      opacity: 1;
      max-height: 50.0rem;
    }

    &-item-list{
      padding: @gap-regular 0;
    }

    &-item {
      padding-left: @gap-regular * 2;
      padding-right: @gap-regular * 2;
      font-size: 1.5rem;

      .header-link {
        padding: @gap-regular 0;
        display: block;
      }
    }
  }

  .media-breakpoint-down (@screen-md, {
    &__chapters {
      display: none !important;
    }
  });
}

// this CSS class comes from Cutwise Player
// iOS Safari doesn't hide header block in fullscreen mode
:global(.cwp-has-fullscreen-element) .header {
  display: none;
}

.header-link {
  font-size: 1.4rem;
  padding: 0 @gap-regular * 2;
  text-decoration: none;
  color: @color-neutral-lightest;
  user-select: none;
  text-align: left;

  &--desktop {
    height: 100%;
    align-items: center;
    display: inline-flex;
    position: relative;
    text-align: center;
    font-weight: 500;

    &.active::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      height: 0.2rem;
      width: 100%;
      background: white;
    }
  }

  &--dropdown {
    cursor: pointer;
  }

  &.active {
    color: @color-white;
  }

  &__triangle {
    font-size: 2.4rem;
    margin-left: @gap-small;
    transition: transform 180ms ease-in-out;
    transform-origin: center center;

    &--opened {
      transform: rotate(180deg);
    }
  }

  .media-breakpoint-down(@screen-lg, {
    padding: 0 @gap-regular;
  });

  .media-breakpoint-down(@screen-md, {
    padding: 0 @gap-regular * 2;
  });
}

.pinned-collection {
  background: var(--pinned-collection-background);
  border-radius: @border-radius-secondary;
  display: inline-flex;
  align-items: center;
  font-size: 1.2rem;
  height: 2.8rem;
  padding: 0 @gap-regular;

  .media-breakpoint-down(@screen-md, {
    display: none;
  });

  &__label {
    margin-right: @gap-small;

    .media-breakpoint-down(@screen-xxlg, {
      display: none;
    });
  }

  &__link {
    color: var(--pinned-collection-color);
    margin-right: @gap-small;
    max-width: 10.0rem;

    .has-three-dots-on-overflow();
  }
}

// todo: move this to separate file

.show-on-small-screens {
  display: none !important;

  .media-breakpoint-down(@screen-lg, {
    display: inline-flex !important;
  });
}

.hide-on-small-screens {
  .media-breakpoint-down(@screen-lg, {
    display: none !important;
  });
}

// hack for ios safari (hide header in fullscreen mode)
:-webkit-full-screen-ancestor:not(iframe) .header {
  display: none;
}

.headerFixed {
  z-index: 5;
  position: fixed;
}

.headerLinkActive {
  font-weight: 500;
  color: var(--header-right-menu-color);

  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    height: 0.2rem;
    width: 100%;
    background: var(--header-right-menu-color);
  }
}

.headerLinkLGD {
  --header-right-menu-color: @color-secondary;
}

.headerProductTypeMenuItem {
  justify-content: flex-start !important;
}

.headerProductTypeMenuWrapper {
  > a.active {
    background: none;
    font-weight: 500;
  }
}

.headerProductTypeIcon {
  display: inline-flex;
  justify-content: center;
  align-content: center;
  height: 3.2rem;
  width: 3.2rem;
  margin-right: @gap-small * 3;
}

.iconFancy {
  color: var(--color-accent);
}
