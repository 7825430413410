@font-face {
    font-family: "CWI";
    src: url("/1738064222787/app.CWI.2eb481801e311a4c66cb.woff2") format("woff2");
}

.cw-icon {
    display: inline-block;
    font-family: CWI !important;
    font-style: normal;
    font-weight: normal !important;
    line-height: 1.2;
    -webkit-font-smoothing: antialiased;
}

.cw-icon:before {
    box-sizing: border-box;
}

    .cw-icon-3d-cardboard:before {
        content: "\f101";
    }
    .cw-icon-3d-model:before {
        content: "\f102";
    }
    .cw-icon-3d-tv:before {
        content: "\f103";
    }
    .cw-icon-4v-360:before {
        content: "\f104";
    }
    .cw-icon-4v-crown:before {
        content: "\f105";
    }
    .cw-icon-4v-left:before {
        content: "\f106";
    }
    .cw-icon-4v-pavillion:before {
        content: "\f107";
    }
    .cw-icon-4v-right:before {
        content: "\f108";
    }
    .cw-icon-4v-side-view-table-down:before {
        content: "\f109";
    }
    .cw-icon-4v-side-view-table-up:before {
        content: "\f10a";
    }
    .cw-icon-add-model:before {
        content: "\f10b";
    }
    .cw-icon-add-person:before {
        content: "\f10c";
    }
    .cw-icon-aperture:before {
        content: "\f10d";
    }
    .cw-icon-arrow-bold-down:before {
        content: "\f10e";
    }
    .cw-icon-arrow-bold-left:before {
        content: "\f10f";
    }
    .cw-icon-arrow-bold-right:before {
        content: "\f110";
    }
    .cw-icon-arrow-lt-down:before {
        content: "\f111";
    }
    .cw-icon-arrow-lt-left:before {
        content: "\f112";
    }
    .cw-icon-arrow-lt-right:before {
        content: "\f113";
    }
    .cw-icon-arrow-lt-up:before {
        content: "\f114";
    }
    .cw-icon-arrow-mid-down:before {
        content: "\f115";
    }
    .cw-icon-arrow-mid-left:before {
        content: "\f116";
    }
    .cw-icon-arrow-mid-right:before {
        content: "\f117";
    }
    .cw-icon-arrow-mid-up:before {
        content: "\f118";
    }
    .cw-icon-arrow-selector-down:before {
        content: "\f119";
    }
    .cw-icon-arrow-selector-up:before {
        content: "\f11a";
    }
    .cw-icon-arrows-asc:before {
        content: "\f11b";
    }
    .cw-icon-arrows-desc:before {
        content: "\f11c";
    }
    .cw-icon-available-data:before {
        content: "\f11d";
    }
    .cw-icon-back-hand:before {
        content: "\f11e";
    }
    .cw-icon-block:before {
        content: "\f11f";
    }
    .cw-icon-bookmark-fill:before {
        content: "\f120";
    }
    .cw-icon-bookmark:before {
        content: "\f121";
    }
    .cw-icon-calendar:before {
        content: "\f122";
    }
    .cw-icon-cancelled:before {
        content: "\f123";
    }
    .cw-icon-carbon-project:before {
        content: "\f124";
    }
    .cw-icon-chat:before {
        content: "\f125";
    }
    .cw-icon-check:before {
        content: "\f126";
    }
    .cw-icon-close:before {
        content: "\f127";
    }
    .cw-icon-code:before {
        content: "\f128";
    }
    .cw-icon-communities:before {
        content: "\f129";
    }
    .cw-icon-compare-fill:before {
        content: "\f12a";
    }
    .cw-icon-compare:before {
        content: "\f12b";
    }
    .cw-icon-completed:before {
        content: "\f12c";
    }
    .cw-icon-controls:before {
        content: "\f12d";
    }
    .cw-icon-cube-rotation:before {
        content: "\f12e";
    }
    .cw-icon-cube:before {
        content: "\f12f";
    }
    .cw-icon-cut-brand:before {
        content: "\f130";
    }
    .cw-icon-cutwise:before {
        content: "\f131";
    }
    .cw-icon-delete:before {
        content: "\f132";
    }
    .cw-icon-description-off:before {
        content: "\f133";
    }
    .cw-icon-description-on:before {
        content: "\f134";
    }
    .cw-icon-diamond-filled:before {
        content: "\f135";
    }
    .cw-icon-diamond:before {
        content: "\f136";
    }
    .cw-icon-distance-to-diamond:before {
        content: "\f137";
    }
    .cw-icon-distance-to-inclusions:before {
        content: "\f138";
    }
    .cw-icon-distance-to-rough:before {
        content: "\f139";
    }
    .cw-icon-documentation:before {
        content: "\f13a";
    }
    .cw-icon-download:before {
        content: "\f13b";
    }
    .cw-icon-drag-handle:before {
        content: "\f13c";
    }
    .cw-icon-drag:before {
        content: "\f13d";
    }
    .cw-icon-edit:before {
        content: "\f13e";
    }
    .cw-icon-email:before {
        content: "\f13f";
    }
    .cw-icon-expand:before {
        content: "\f140";
    }
    .cw-icon-facebook:before {
        content: "\f141";
    }
    .cw-icon-failed:before {
        content: "\f142";
    }
    .cw-icon-file-conversion:before {
        content: "\f143";
    }
    .cw-icon-filter:before {
        content: "\f144";
    }
    .cw-icon-folder:before {
        content: "\f145";
    }
    .cw-icon-full-protect:before {
        content: "\f146";
    }
    .cw-icon-fullscreen-off:before {
        content: "\f147";
    }
    .cw-icon-fullscreen-on:before {
        content: "\f148";
    }
    .cw-icon-grayscale:before {
        content: "\f149";
    }
    .cw-icon-gyro:before {
        content: "\f14a";
    }
    .cw-icon-hamburger:before {
        content: "\f14b";
    }
    .cw-icon-help:before {
        content: "\f14c";
    }
    .cw-icon-history-event:before {
        content: "\f14d";
    }
    .cw-icon-history:before {
        content: "\f14e";
    }
    .cw-icon-inclusion:before {
        content: "\f14f";
    }
    .cw-icon-inclusions-inside-old:before {
        content: "\f150";
    }
    .cw-icon-inclusions-inside:before {
        content: "\f151";
    }
    .cw-icon-info:before {
        content: "\f152";
    }
    .cw-icon-information:before {
        content: "\f153";
    }
    .cw-icon-jewelry:before {
        content: "\f154";
    }
    .cw-icon-leia-tablet:before {
        content: "\f155";
    }
    .cw-icon-lens-f-11:before {
        content: "\f156";
    }
    .cw-icon-lens-f-16:before {
        content: "\f157";
    }
    .cw-icon-lens-f-28:before {
        content: "\f158";
    }
    .cw-icon-lens-f-infinity:before {
        content: "\f159";
    }
    .cw-icon-lighting-d65:before {
        content: "\f15a";
    }
    .cw-icon-lighting-fluorescent:before {
        content: "\f15b";
    }
    .cw-icon-lighting-led:before {
        content: "\f15c";
    }
    .cw-icon-lighting:before {
        content: "\f15d";
    }
    .cw-icon-like-fill:before {
        content: "\f15e";
    }
    .cw-icon-like:before {
        content: "\f15f";
    }
    .cw-icon-link:before {
        content: "\f160";
    }
    .cw-icon-long-arrow-right:before {
        content: "\f161";
    }
    .cw-icon-marquise:before {
        content: "\f162";
    }
    .cw-icon-media:before {
        content: "\f163";
    }
    .cw-icon-metrics-brightness-faceup:before {
        content: "\f164";
    }
    .cw-icon-metrics-brightness:before {
        content: "\f165";
    }
    .cw-icon-metrics-brilliance:before {
        content: "\f166";
    }
    .cw-icon-metrics-carat:before {
        content: "\f167";
    }
    .cw-icon-metrics-clarity:before {
        content: "\f168";
    }
    .cw-icon-metrics-color-material:before {
        content: "\f169";
    }
    .cw-icon-metrics-color-table:before {
        content: "\f16a";
    }
    .cw-icon-metrics-color:before {
        content: "\f16b";
    }
    .cw-icon-metrics-culet:before {
        content: "\f16c";
    }
    .cw-icon-metrics-cut-perf:before {
        content: "\f16d";
    }
    .cw-icon-metrics-cut:before {
        content: "\f16e";
    }
    .cw-icon-metrics-durability:before {
        content: "\f16f";
    }
    .cw-icon-metrics-fire:before {
        content: "\f170";
    }
    .cw-icon-metrics-fluor-table:before {
        content: "\f171";
    }
    .cw-icon-metrics-fluor:before {
        content: "\f172";
    }
    .cw-icon-metrics-girdle:before {
        content: "\f173";
    }
    .cw-icon-metrics-ident:before {
        content: "\f174";
    }
    .cw-icon-metrics-identification:before {
        content: "\f175";
    }
    .cw-icon-metrics-measurments:before {
        content: "\f176";
    }
    .cw-icon-metrics-normalized–cut-perf:before {
        content: "\f177";
    }
    .cw-icon-metrics-optical-performance-area:before {
        content: "\f178";
    }
    .cw-icon-metrics-optical-performance:before {
        content: "\f179";
    }
    .cw-icon-metrics-optical-symmetry:before {
        content: "\f17a";
    }
    .cw-icon-metrics-polish:before {
        content: "\f17b";
    }
    .cw-icon-metrics-scintillation:before {
        content: "\f17c";
    }
    .cw-icon-metrics-shape:before {
        content: "\f17d";
    }
    .cw-icon-metrics-spread:before {
        content: "\f17e";
    }
    .cw-icon-metrics-symmetry:before {
        content: "\f17f";
    }
    .cw-icon-metrics-transparency:before {
        content: "\f180";
    }
    .cw-icon-models-linked:before {
        content: "\f181";
    }
    .cw-icon-more:before {
        content: "\f182";
    }
    .cw-icon-multistage:before {
        content: "\f183";
    }
    .cw-icon-my-lists:before {
        content: "\f184";
    }
    .cw-icon-next:before {
        content: "\f185";
    }
    .cw-icon-oval:before {
        content: "\f186";
    }
    .cw-icon-palette:before {
        content: "\f187";
    }
    .cw-icon-pause:before {
        content: "\f188";
    }
    .cw-icon-pencil:before {
        content: "\f189";
    }
    .cw-icon-pending:before {
        content: "\f18a";
    }
    .cw-icon-pin-off:before {
        content: "\f18b";
    }
    .cw-icon-pin-on:before {
        content: "\f18c";
    }
    .cw-icon-pinterest:before {
        content: "\f18d";
    }
    .cw-icon-play:before {
        content: "\f18e";
    }
    .cw-icon-plus:before {
        content: "\f18f";
    }
    .cw-icon-precise-fast:before {
        content: "\f190";
    }
    .cw-icon-prev:before {
        content: "\f191";
    }
    .cw-icon-princess:before {
        content: "\f192";
    }
    .cw-icon-printer:before {
        content: "\f193";
    }
    .cw-icon-private-access-for-users:before {
        content: "\f194";
    }
    .cw-icon-products-new:before {
        content: "\f195";
    }
    .cw-icon-products-removed:before {
        content: "\f196";
    }
    .cw-icon-project-shared:before {
        content: "\f197";
    }
    .cw-icon-project:before {
        content: "\f198";
    }
    .cw-icon-protect:before {
        content: "\f199";
    }
    .cw-icon-qr:before {
        content: "\f19a";
    }
    .cw-icon-radar:before {
        content: "\f19b";
    }
    .cw-icon-reprojection:before {
        content: "\f19c";
    }
    .cw-icon-reset-camera:before {
        content: "\f19d";
    }
    .cw-icon-rough-2:before {
        content: "\f19e";
    }
    .cw-icon-rough-caverns:before {
        content: "\f19f";
    }
    .cw-icon-rough-inclusions:before {
        content: "\f1a0";
    }
    .cw-icon-rough-shared:before {
        content: "\f1a1";
    }
    .cw-icon-rough-surface:before {
        content: "\f1a2";
    }
    .cw-icon-rough:before {
        content: "\f1a3";
    }
    .cw-icon-round:before {
        content: "\f1a4";
    }
    .cw-icon-search:before {
        content: "\f1a5";
    }
    .cw-icon-second-display:before {
        content: "\f1a6";
    }
    .cw-icon-selector-down:before {
        content: "\f1a7";
    }
    .cw-icon-selector-up:before {
        content: "\f1a8";
    }
    .cw-icon-settings-off:before {
        content: "\f1a9";
    }
    .cw-icon-settings-on:before {
        content: "\f1aa";
    }
    .cw-icon-share-outline:before {
        content: "\f1ab";
    }
    .cw-icon-share:before {
        content: "\f1ac";
    }
    .cw-icon-show-all:before {
        content: "\f1ad";
    }
    .cw-icon-singlescale-off:before {
        content: "\f1ae";
    }
    .cw-icon-singlescale-on:before {
        content: "\f1af";
    }
    .cw-icon-sort-down:before {
        content: "\f1b0";
    }
    .cw-icon-sort-up:before {
        content: "\f1b1";
    }
    .cw-icon-spectrum-cuboid:before {
        content: "\f1b2";
    }
    .cw-icon-spectrum-cushion-fancy:before {
        content: "\f1b3";
    }
    .cw-icon-spectrum-cushion:before {
        content: "\f1b4";
    }
    .cw-icon-spectrum-emerald:before {
        content: "\f1b5";
    }
    .cw-icon-spectrum-heart:before {
        content: "\f1b6";
    }
    .cw-icon-spectrum-lighting-d65:before {
        content: "\f1b7";
    }
    .cw-icon-spectrum-lighting-fluorescent:before {
        content: "\f1b8";
    }
    .cw-icon-spectrum-lighting-led:before {
        content: "\f1b9";
    }
    .cw-icon-spectrum-lighting:before {
        content: "\f1ba";
    }
    .cw-icon-spectrum-marquise:before {
        content: "\f1bb";
    }
    .cw-icon-spectrum-other:before {
        content: "\f1bc";
    }
    .cw-icon-spectrum-oval-fancy:before {
        content: "\f1bd";
    }
    .cw-icon-spectrum-oval:before {
        content: "\f1be";
    }
    .cw-icon-spectrum-pear:before {
        content: "\f1bf";
    }
    .cw-icon-spectrum-princess:before {
        content: "\f1c0";
    }
    .cw-icon-spectrum-radiant:before {
        content: "\f1c1";
    }
    .cw-icon-spectrum-rough:before {
        content: "\f1c2";
    }
    .cw-icon-spectrum-round:before {
        content: "\f1c3";
    }
    .cw-icon-star-outline:before {
        content: "\f1c4";
    }
    .cw-icon-star:before {
        content: "\f1c5";
    }
    .cw-icon-swap:before {
        content: "\f1c6";
    }
    .cw-icon-theme-dark:before {
        content: "\f1c7";
    }
    .cw-icon-theme-light:before {
        content: "\f1c8";
    }
    .cw-icon-touch-inclusions:before {
        content: "\f1c9";
    }
    .cw-icon-user-billing-eur:before {
        content: "\f1ca";
    }
    .cw-icon-user-billing-usd:before {
        content: "\f1cb";
    }
    .cw-icon-user-import:before {
        content: "\f1cc";
    }
    .cw-icon-user-logout:before {
        content: "\f1cd";
    }
    .cw-icon-user-my-collections:before {
        content: "\f1ce";
    }
    .cw-icon-user-profile:before {
        content: "\f1cf";
    }
    .cw-icon-view-big:before {
        content: "\f1d0";
    }
    .cw-icon-view-chart:before {
        content: "\f1d1";
    }
    .cw-icon-view-combo-lg:before {
        content: "\f1d2";
    }
    .cw-icon-view-combo:before {
        content: "\f1d3";
    }
    .cw-icon-view-ex-small:before {
        content: "\f1d4";
    }
    .cw-icon-view-little:before {
        content: "\f1d5";
    }
    .cw-icon-view-small:before {
        content: "\f1d6";
    }
    .cw-icon-view-tab:before {
        content: "\f1d7";
    }
    .cw-icon-warning:before {
        content: "\f1d8";
    }
    .cw-icon-wechat:before {
        content: "\f1d9";
    }
    .cw-icon-whatsapp:before {
        content: "\f1da";
    }
    .cw-icon-window:before {
        content: "\f1db";
    }
    .cw-icon-x-twitter:before {
        content: "\f1dc";
    }
    .cw-icon-zoom-in:before {
        content: "\f1dd";
    }
    .cw-icon-zoom-out:before {
        content: "\f1de";
    }
    .cw-icon-zoom-to-fit:before {
        content: "\f1df";
    }
