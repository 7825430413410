@import "styles/mixins/_mixins.less";

.fancyCheckbox {
  display: inline-flex;
  justify-content: flex-start;
  align-items: flex-start;
  cursor: pointer;
  user-select: none;

  &.fancyCheckboxDisabled {
    cursor: default;
  }

  --checkbox-mark-color: var(--color-white);

  .fancyCheckboxLabel {
    display: inline-block;
    margin-left: 0.7rem;
    position: relative;
    top: 0.1rem;
  }

  .fancyCheckboxLabelDisabled {
    color: var(--color-neutral-light);
  }

  .checkmark {
    height: 1.8rem;
    width: 1.8rem;
    min-width: 1.8rem;
    border: thin solid var(--color-neutral-lightest);
    position: relative;

    .ambientDash {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      display: flex;
      align-items: center;
      justify-content: center;

      &:after {
        content: "";
        display: block;
        position: absolute;
        left: 50%;
        top: 50%;
        height: 2px;
        transform: translate(-4.5px, -1px);
        width: 9px;
        background: var(--color-white);
      }
    }
  }

  &--radio {
    .checkmark {
      padding: 0.2rem;
      border-radius: 50%;
      margin-top: 1px;

      &:after {
        content: "";
        display: none;
        background: var(--color-white);
        height: 100%;
        width: 100%;
        border-radius: 50%;
      }
    }
  }

  &--checkbox {
    .checkmark {
      &:after {
        content: "";
        display: none;
        position: relative;
        left: 0.5rem;
        top: 0;
        height: 1rem;
        width: 0.5rem;
        border: solid var(--checkbox-mark-color);
        border-width: 0 0.2rem 0.2rem 0;
        transform: rotate(45deg);
      }
    }
  }

  &--ambient {
    .checkmark {
      padding: 0;

      &:after {
        background: var(--color-neutral-light);
      }
    }
  }

  &:hover input ~ .checkmark {
    border-color: var(--color-white);
    background: var(--color-grey-7);
  }

  &:focus {
    outline: none;

    input {
      & ~ .checkmark {
        box-shadow: 0 0 0.3rem var(--color-white);
      }
      &:disabled {
        & ~ .checkmark {
          box-shadow: none;
        }
      }
    }
  }

  input {
    display: none;
  }

  input:checked ~ .checkmark {
    &:after {
      display: block;
    }
  }

  input:disabled {
    & ~ .checkmark {
      cursor: default;
      background-color: var(--color-neutral);
      border-color: var(--color-neutral-light);
    }
    &:hover {
      ~ .checkmark {
        border-color: var(--color-neutral-light);
      }
    }
    &:checked {
      & ~ .checkmark {
        &:after {
          border-color: var(--color-neutral-light);
        }
      }
    }
  }
}

.fancyCheckboxBlack {
  --checkbox-mark-color: var(--color-black);

  &.fancyCheckbox--radio .checkmark:after {
    background-color: var(--color-neutral);
  }
}
